import { reactive, toRefs, getCurrentInstance, watch, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import swal from 'sweetalert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const query = route.query
  const prof = ref(null) // ref
  const state = reactive({
    noticeId: query.noticeId,
    questionType: '',
    title: '',
    contents: '',
    phone: '',
    email: '',
    name: '',
    category: [],
    file: '',
    fileNm: '',
    fileLoc: ''
  })
  /** watch **/
  watch(
    () => state.file,
    val => {
      if (typeof val === 'object') {
        const reader = new FileReader()

        reader.onload = event => {
          prof.value.src = event.target.result
        }
        reader.readAsDataURL(val)
      } else {
        prof.value.src = ''
      }
    }
  )

  const fnDetail = async () => {
    const res = await proxy.$CustomerSvc.postMyQuestionDetail(route.query)
    if (res.resultCode === '0000') {
      state.noticeId = res.noticeId
      const categoryMap = proxy.$_.keyBy(state.category, 'questionNm')
      state.questionType = categoryMap[res.questionType].questionType
      state.title = res.title
      state.contents = res.contents
      state.fileNm = res.fileNm
      state.fileLoc = res.fileLoc
    }
  }

  const fnInfo = async () => {
    const res = await proxy.$CustomerSvc.postMyQuestionInfo()
    if (res.resultCode === '0000') {
      state.name = res.user.name
      state.email = res.user.email
      state.phone = res.user.phone
      state.category = res.category
    }
  }
  const fnSave = async () => {
    if (!isValid()) return
    const request = {}
    request.noticeId = proxy.$Util.isEmpty(state.noticeId) ? '' : state.noticeId
    request.questionType = state.questionType
    request.title = state.title
    request.contents = state.contents
    request.phone = state.phone
    request.email = state.email
    request.name = state.name
    request.changeYn = typeof state.file === 'object' ? 'Y' : 'N'
    const params = {
      request: JSON.stringify(request),
      file: state.file
    }
    const res = await proxy.$CustomerSvc.postMyQuestionSave(params)
    if (res.resultCode === '0000') {
      let alert = function(msg, type) {
        swal({
          title: '',
          text: msg,
          type: type,
          customClass: 'sweet-size'
        })
      }
      alert(
        '1:1 문의가 정상적으로 접수되었습니다.\n영업시간(평일 08:00~17:00) 외에는 답변이 지연될 수 있습니다.'
      )
      await router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }
  const fnChange = event => {
    if (typeof event.target.files[0] === 'object') {
      const maxSize = 10 * 1024 * 1024
      const fileName = event.target.files[0].name

      const checkFileTypes = ['jpg', 'gif', 'png']
      const isChecked = checkFileTypes.some(type => {
        return fileName.endsWith(type)
      })
      const fileSize = event.target.files[0].size
      if (fileSize > maxSize || !isChecked) {
        alert('첨부파일은 10MB 미만의 jpg, png, gif 파일만 등록해주세요.')
        state.file = ''
        state.fileNm = ''
      } else {
        state.file = event.target.files[0]
        state.fileNm = event.target.files[0].name
      }
    } else {
      state.file = ''
      state.fileNm = ''
    }
  }
  const isValid = () => {
    if (proxy.$Util.isEmpty(state.title)) {
      alert('제목을 입력해 주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.questionType)) {
      alert('질문을 선택해 주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.name)) {
      alert('이름을 입력해 주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.email)) {
      alert('이메일을 입력해 주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.phone)) {
      alert('휴대폰번호를 입력해 주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.contents)) {
      alert('내용을 입력해 주세요.')
      return false
    }
    return true
  }
  const init = async () => {
    await fnInfo()
    if (!proxy.$Util.isEmpty(route.query.noticeId)) {
      await fnDetail()
    }
  }
  /** init **/
  init()
  return { prof, ...toRefs(state), fnInfo, fnSave, fnChange }
}
