<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>1:1 문의/신고</span>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl essen">질문 선택</div>
                  <div class="form_in">
                    <label class="select">
                      <select v-model="questionType">
                        <option :value="''">질문을 선택해 주세요.</option>
                        <option
                          v-for="(item, index) in category"
                          :key="index"
                          :value="item.questionType"
                          >{{ item.questionNm }}</option
                        >
                      </select>
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">제목</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="제목을 입력해 주세요."
                        :maxlength="45"
                        v-model="title"
                      />
                    </label>
                  </div>
                </li>
                <!-- <li class="form_li">
                  <div class="form_ttl essen">이름</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="이름을 입력해 주세요."
                        v-model="name"
                      />
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">이메일</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="email"
                        placeholder="이메일 주소를 입력해 주세요."
                        v-model="email"
                      />
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">휴대폰</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="email"
                        placeholder="휴대폰 번호(숫자만 입력)"
                        v-model="phone"
                      />
                    </label>
                  </div>
                </li> -->
                <li class="form_li">
                  <div class="form_ttl essen">내용</div>
                  <div class="form_in">
                    <label>
                      <textarea
                        placeholder="500자 이내로 입력해 주세요."
                        maxlength="500"
                        v-model="contents"
                      ></textarea>
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">첨부 파일</div>
                  <div class="form_in">
                    <label class="input_file">
                      <input
                        type="file"
                        accept="image/gif, image/jpeg, image/png"
                        @change="fnChange"
                        onclick="this.value = ''"
                      />
                      <span class="label bo">파일 선택</span>
                    </label>
                    <div
                      class="input_file_name"
                      v-if="typeof file === 'object' || !$Util.isEmpty(fileNm)"
                    >
                      <div class="prof">
                        <div class="img_area">
                          <img
                            :src="
                              $Util.isEmpty(fileLoc)
                                ? '@/assets/static/images/sample_prof.png'
                                : fileLoc
                            "
                            alt=""
                            ref="prof"
                          />
                        </div>
                      </div>
                      <span>{{ fileNm }}</span>
                    </div>
                    <div class="hint">
                      첨부파일은 10MB 미만의 jpg, png, gif 파일만 등록해주세요.
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnSave">문의하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'cs-my-question-write-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
